
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

$.sum = function(arr) {
    var r = 0;
    $.each(arr, function(i, v) {
        r += +v;
    });
    return r;
}
var allEditors = document.querySelectorAll('#admin textarea');
for (var i = 0; i < allEditors.length; ++i) {
  ClassicEditor.create(allEditors[i]);
}

$(function() {
    $(document).ready(function() {
      var text_item1 = $('#name_item1').val();
      var text_item2 = $('#name_item2').val();
        var alle = $('input[type="radio"]:checked');
        var item1 = [];
        var item2 = [];
        alle.each( function(){
            var frage = $(this).data('frage');

            var item1_punkte = $('[data-frage='+frage+'][data-item1]:checked').val();
            var item2_punkte = $('[data-frage='+frage+'][data-item2]:checked').val();

            var faktor=$('[data-frage='+frage+'][data-faktor]:checked').val();
            item1[frage]=item1_punkte*faktor;
            item2[frage]=item2_punkte*faktor;
            //console.log(faktor*wp);
        });
        item1 = item1.filter(function (el) {
            return el != null;
        });
        item2 = item2.filter(function (el) {
            return el != null;
        });
        $('#punkte_item1').val($.sum(item1)+' Punkte');
        $('#punkte_item2').val($.sum(item2)+' Punkte');
        if($.sum(item1)>$.sum(item2)){
            $('#winner').text(text_item1);
        } else {
            $('#winner').text(text_item2);
        }
    });
    $('input[type="radio"]').on('change', function() {
        var alle = $('input[type="radio"]:checked');
        var item1 = [];
        var item2 = [];
        alle.each( function(){
            var frage = $(this).data('frage');
            //console.log($(this));
            var item1_punkte = $('[data-frage='+frage+'][data-item1]:checked').val();
            var item2_punkte = $('[data-frage='+frage+'][data-item2]:checked').val();

            var faktor=$('[data-frage='+frage+'][data-faktor]:checked').val();
            item1[frage]=item1_punkte*faktor;
            item2[frage]=item2_punkte*faktor;
            //console.log(faktor*wp);
        });
        item1 = item1.filter(function (el) {
            return el != null;
        });
        item2 = item2.filter(function (el) {
            return el != null;
        });
        $('#punkte_item1').val($.sum(item1)+' Punkte');
        $('#punkte_item2').val($.sum(item2)+' Punkte');
        var text_item1 = $('#name_item1').val();
        var text_item2 = $('#name_item2').val();
        if($.sum(item1)>$.sum(item2)){

            $('#winner').text(text_item1);
        } else {
            $('#winner').text(text_item2);
        }
    });
});
